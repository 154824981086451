import React from "react";
import { Component } from "react";
import { Text, View } from "../components/base";
import { Header, Spinner } from "../components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { format, isFuture, getYear, compareDesc, endOfDay } from "date-fns";
import { actions, connect } from "../store";

import "./Shows.css";

const Show = ({ id, date, isFuture, name, venue, link, note }) => {
  const text = `${format(date, "D/M")} ${name} ${venue ? `@ ${venue}` : ""}`;

  return (
    <View className={`Shows-show Shows-show-${isFuture ? "future" : "past"}`}>
      <Text color="white">
        {link ? (
          <a
            className={`Shows-show-link`}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            title={note}
          >
            {text}
          </a>
        ) : (
          text
        )}
      </Text>
    </View>
  );
};

class Shows extends Component {
  componentDidMount() {
    actions.setTheme("Shows");
    actions.fetchShows();
  }

  render() {
    const { error, loading, showsByYear } = this.props;
    if (error) {
      return <p>Error...</p>;
    }

    if (loading) {
      return <Spinner />;
    }

    return (
      <View>
        <Header
          color="white"
          level={2}
          title="Shows"
          className="Shows-header"
        />
        <Grid fluid={true}>
          <Row center="xs" className="Shows-list">
            {Object.keys(showsByYear)
              .reverse()
              .map(year => (
                <Col xs={12} key={year} className="Shows-section">
                  <Header
                    color="white"
                    level={3}
                    className="Shows-section-header"
                    title={year}
                  />
                  {showsByYear[year].map(show => (
                    <Show key={show.id} {...show} />
                  ))}
                </Col>
              ))}
          </Row>
        </Grid>
      </View>
    );
  }
}

const mapStateToProps = ({ shows: { error, loading, entries } }) => {
  const showsByYear = (entries || [])
    .sort((a, b) => compareDesc(a.date, b.date))
    .reduce((shows, show) => {
      const year = getYear(show.date).toString();

      if (!shows[year]) {
        shows[year] = [];
      }

      shows[year].push({
        ...show,
        isFuture: isFuture(endOfDay(show.date))
      });

      return shows;
    }, {});

  return {
    error,
    loading,
    showsByYear
  };
};

export default connect(mapStateToProps)(Shows);
