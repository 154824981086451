import React from "react";
import { View } from ".";

import "./Text.css";

const Text = ({ center, color, children, cursive = false, className = "" }) => (
  <View
    className={`Text Text-${color ? color : "default"} Text-${
      center ? "center" : "left"
    } Text-${cursive ? "cursive" : "standard"} ${className}`}
  >
    {children}
  </View>
);

export default Text;
