import React, { Component } from "react";
import { Text, View } from "../components/base";
import { Header, Spinner } from "../components";
import { Link, withRouter } from "react-router-dom";
import { Grid, Row, Col } from "react-flexbox-grid";
import { actions, connect } from "../store";
import { APIUtilities, PostUtilities } from "../utilities";

import "./Posts.css";

class Posts extends Component {
  state = {
    post: null,
    posts: null
  };

  componentDidMount() {
    actions.setTheme("Shows");
    this.setup();
  }

  async setup() {
    const {
      endpoint,
      match: {
        params: { slug }
      }
    } = this.props;

    if (slug) {
      const post = await APIUtilities.getPostBySlug(endpoint, slug);
      if (post) {
        console.log(post);

        this.setState({ post });
      }
    } else {
      const posts = await APIUtilities.getPosts(endpoint);
      if (posts) {
        this.setState({ post: null, posts });
      }
    }
  }

  componentDidUpdate({
    match: {
      params: { slug: prevSlug }
    }
  }) {
    const {
      match: {
        params: { slug }
      }
    } = this.props;
    if (prevSlug !== slug) {
      this.setup();
    }
  }

  render() {
    const { post, posts } = this.state;
    const { baseURI } = this.props;

    return (
      <View className="Updates">
        <Header
          color="white"
          level={2}
          title="Updates"
          className="Posts-header"
        />
        <Grid fluid={true}>
          {post ? (
            <Row center="xs">
              <Col xs={12} md={8} lg={6}>
                <Header level={3} title={post.title} className="Posts-title" />
                {post.image && (
                  <img
                    className="Posts-image"
                    src={PostUtilities.getImageURL(
                      baseURI,
                      post.image.filename
                    )}
                    // src={post.image.data.thumbnails[0].url}
                    alt={post.title}
                  />
                )}
                <Text className="Posts-lead">{post.lead}</Text>
                <Text className="Posts-content">
                  <span dangerouslySetInnerHTML={{ __html: post.content }} />
                </Text>
              </Col>
            </Row>
          ) : posts ? (
            <View className="Posts-list">
              <Row center="xs">
                <Col xs={12} md={6}>
                  <Row>
                    {posts.map(post => (
                      <Col xs={12} key={post.slug} className="Posts-list-item">
                        <View>
                          <Link
                            to={`/posts/${post.slug}`}
                            className="Posts-list-item-title"
                          >
                            <Header
                              level={4}
                              align="left"
                              title={post.title}
                              className="Posts-title"
                            />
                          </Link>
                          <View className="Posts-list-item-lead">
                            {post.lead}
                          </View>
                        </View>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </View>
          ) : (
            <Spinner />
          )}
        </Grid>
      </View>
    );
  }
}
const mapStateToProps = ({ api: { baseURI, endpoint } }) => ({
  baseURI,
  endpoint
});

export default withRouter(connect(mapStateToProps)(Posts));
