import { APIUtilities } from "../utilities";

const fetchShows = async (
  { api: { endpoint }, shows },
  actions,
  trigger = false
) => {
  if (!trigger) {
    await actions.fetchShows(true);
  } else {
    return {
      shows: {
        loading: true
      }
    };
  }

  try {
    const entries = await APIUtilities.getShows(endpoint);

    return {
      shows: {
        loading: false,
        entries
      }
    };
  } catch (e) {
    return {
      shows: {
        error: e.message,
        loading: false
      }
    };
  }
};

export default { fetchShows };
