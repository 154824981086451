import React from "react";
import "./Spinner.css";

const Spinner = ({ show }) => {
  console.log(show);
  const style = {};

  if (!show) {
    style.opacity = 0;
  }

  return (
    <div className="Spinner-container" style={style}>
      <img
        className="Spinner"
        src="/assets/images/logo_alt.png"
        alt="loading icon"
        width="150"
      />
    </div>
  );
};

export default Spinner;
