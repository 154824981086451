import React, { Component } from "react";
import { View } from "./base";
import { connect, actions } from "../store";

import "./MenuButton.css";

class MenuButton extends Component {
  onHandleClick = () => {
    const { menuVisible } = this.props;
    actions.setMenuVisible(!menuVisible);
  };

  render() {
    const { menuVisible } = this.props;

    return (
      <button
        className={`MenuButton MenuButton-${menuVisible ? "open" : "closed"}`}
        onClick={this.onHandleClick}
      >
        <View>
          <View />
          <View />
          <View />
        </View>
      </button>
    );
  }
}

const mapStateToProps = ({ menuVisible }) => ({ menuVisible });

export default connect(mapStateToProps)(MenuButton);
