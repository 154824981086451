import { APIUtilities } from "../utilities";

const loadSettings = async (
  { api: { endpoint } },
  actions,
  trigger = false
) => {
  if (!trigger) {
    await actions.loadSettings(true);
  } else {
    return {
      settings: {
        loading: true
      }
    };
  }

  try {
    const settings = await APIUtilities.getSettings(endpoint);

    return {
      settings: {
        error: null,
        loading: false,
        ...settings
      }
    };
  } catch (e) {
    return {
      settings: {
        error: e.message,
        loading: false
      }
    };
  }
};

const setTheme = (state, actions, theme) => {
  return { theme };
};

const setMenuVisible = (state, actions, menuVisible) => {
  return {
    menuVisible
  };
};

export default {
  loadSettings,
  setTheme,
  setMenuVisible
};
