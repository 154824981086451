import React from "react";
import { View } from "./base";

import "./Social.css";
import facebook from "simple-icons/icons/facebook";
import soundcloud from "simple-icons/icons/soundcloud";
import instagram from "simple-icons/icons/instagram";
import spotify from "simple-icons/icons/spotify";
import applemusic from "simple-icons/icons/applemusic";
import youtube from "simple-icons/icons/youtube";

const SOCIAL_LINKS = [
  {
    name: "Facebook",
    icon: facebook.svg,
    link: "https://www.facebook.com/loljudofficial"
  },
  {
    name: "Soundcloud",
    icon: soundcloud.svg,
    link: "https://soundcloud.com/loljudofficial"
  },
  {
    name: "Instagram",
    icon: instagram.svg,
    link: "http://instagram.com/loljud_"
  },
  {
    name: "Spotify",
    icon: spotify.svg,
    link: "https://open.spotify.com/artist/4JPGki1LPeNqQukIYEJXVE"
  },
  {
    name: "Apple Music",
    icon: applemusic.svg,
    link: "https://itun.es/se/i4h-cb"
  },
  {
    name: "YouTube",
    icon: youtube.svg,
    link:
      "https://www.youtube.com/playlist?list=PLUxT0F5kUCR24ShLZNCPzzTsQxeJmwzIQ"
  }
];

const Social = () => (
  <View className="Social">
    {SOCIAL_LINKS.map(({ name, icon, link }) => (
      <a
        key={name}
        className="Social-link"
        href={link}
        target="_blank"
        title={name}
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{ __html: icon }}
      />
    ))}
  </View>
);

export default Social;
