const apiRequest = async (endpoint, action) => {
  try {
    const response = await fetch(`${endpoint}/${action}`);
    const json = await response.json();
    return json.data;
  } catch (e) {
    throw e;
  }
};

const getPostBySlug = (endpoint, slug) => {
  return apiRequest(
    endpoint,
    `items/loljud_pages?filter[slug][eq]=${slug}&filter[published][nnull]&single=1&fields=*,image.filename&sort=-publish_date`
  );
};

const getPostByID = (endpoint, id) => {
  return apiRequest(
    endpoint,
    `items/loljud_pages/${id}?single=1&filter[published][nnull]&fields=*,image.filename`
  );
};

const getPosts = endpoint => {
  return apiRequest(
    endpoint,
    `items/loljud_pages?filter[published][nnull]&fields=*,image.filename&sort=-publish_date`
  );
};

const getShows = endpoint => {
  return apiRequest(endpoint, `items/loljud_shows`);
};

const getContact = endpoint => {
  return apiRequest(
    endpoint,
    `items/loljud_contact?single=1filter[published][nnull]&fields=*`
  );
};

const getSettings = endpoint => {
  return apiRequest(
    endpoint,
    `items/loljud_app?single=1&fields=*,page_links.*`
  );
};

export default {
  getContact,
  getPostBySlug,
  getPostByID,
  getPosts,
  getShows,
  getSettings
};
