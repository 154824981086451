import React from "react";
import { Text } from "./base";
import "./Header.css";

const Header = ({
  align = "center",
  className = "",
  color = "white",
  title,
  level = 1
}) => (
  <header
    className={`Header Header-align-${align} Header-level-${level} ${className}`}
  >
    <Text color={color}>{title}</Text>
  </header>
);

export default Header;
