import React from "react";
import { Component } from "react";
import { View } from "./base";
import { withRouter } from "react-router-dom";
import { connect } from "../store";
import { Spinner } from "./";

import "./Screen.css";

class Screen extends Component {
  state = {
    currentTheme: null,
    prevTheme: null
  };

  componentDidMount() {
    if (this.props.theme) {
      this.setState({ currentTheme: this.props.theme });
    }
  }

  componentDidUpdate({ theme: prevTheme }) {
    const { theme } = this.props;

    if (prevTheme !== theme) {
      this.setState({
        prevTheme,
        currentTheme: theme
      });
    }
  }

  render() {
    const {
      className = "",
      children,
      fullscreen = false,
      loading,
      menuVisible
    } = this.props;
    const { currentTheme, prevTheme } = this.state;
    console.log("loading", loading);

    return (
      <View
        className={`Screen Screen-${
          fullscreen ? "fullscreen" : "default"
        } theme-${currentTheme} ${
          menuVisible ? "Screen-menu-visible" : "Screen-menu-hidden"
        } ${className}`}
      >
        {prevTheme && (
          <View
            key={prevTheme}
            className={`theme-previous theme-${prevTheme}`}
          />
        )}

        <Spinner show={loading === true} />

        {!loading && <View className="Screen-content">{children}</View>}
      </View>
    );
  }
}

const mapStateToProps = ({ settings: { loading }, theme, menuVisible }) => ({
  loading,
  theme,
  menuVisible
});

export default withRouter(connect(mapStateToProps)(Screen));
