import React, { Component } from "react";
import { Text, View } from "../components/base";
import { Header } from "../components";
import { Grid, Row, Col } from "react-flexbox-grid";
import { actions, connect } from "../store";

import "./Contact.css";
import { APIUtilities } from "../utilities";

class Contact extends Component {
  state = {
    contactEmail: null,
    contactManagement: null
  };

  componentDidMount() {
    actions.setTheme("Home");
    this.setup();
  }

  async setup() {
    const { endpoint } = this.props;

    const contact = await APIUtilities.getContact(endpoint);

    if (contact && contact.length > 0) {
      this.setState({
        contactEmail: contact[0].contact_email,
        contactManagement: contact[0].contact_management
      });
    }
  }

  render() {
    const { contactEmail, contactManagement } = this.state;

    return (
      <View>
        <Header
          color="white"
          level={2}
          title="Contact"
          className="Contact-header"
        />
        <Grid fluid={true}>
          <Row center="xs">
            <Col xs={12} md={4}>
              <Header color="white" level={3} title="Contact" />
              <View>
                <Text color="white">{contactEmail}</Text>
              </View>
            </Col>
            <Col xs={12} md={4}>
              <Header color="white" level={3} title="Management & bookings" />
              <View>
                <Text color="white">{contactManagement}</Text>
              </View>
            </Col>
          </Row>
          <Row>
            <Col xs={true}>
              <img
                className="Contact-logo"
                alt="logo"
                src="/assets/images/logo_face.png"
              />
            </Col>
          </Row>
        </Grid>
      </View>
    );
  }
}

const mapStateToProps = ({ api: { baseURI, endpoint } }) => ({
  baseURI,
  endpoint
});

export default connect(mapStateToProps)(Contact);
