import createStore from "react-waterfall";
import { AppActions, PostActions, ShowActions } from "./actions";
import config from "./config/app.json";

const store = {
  actionsCreators: {
    ...AppActions,
    ...PostActions,
    ...ShowActions
  },
  initialState: {
    ...config,
    settings: {
      error: null,
      loading: true,
      menuVisible: false
    },
    theme: "loading",
    shows: {
      loading: false,
      entries: null
    }
  }
};

export const { Provider, connect, actions } = createStore(store);
