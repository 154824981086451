import React, { Component } from "react";
import { Text, View } from "../components/base";
import { Header, Social } from "../components";
import { Link } from "react-router-dom";
import { actions, connect } from "../store";
import { Grid, Row, Col } from "react-flexbox-grid";

import "./Home.css";

class HomeScreen extends Component {
  async componentDidMount() {
    actions.setTheme("Home");
  }

  render() {
    const { title, description, page_links } = this.props;

    return (
      <View>
        <Header title={title} className="Home-header" />
        <Grid>
          <Row>
            <Col xs={true}>
              <Text center={true} className="Home-summary">
                {description}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Social />
            </Col>
          </Row>
          {page_links && (
            <Row>
              <Col xs={12}>
                <Text cursive center className="Home-teaser">
                  <Link to={`/posts/${page_links.slug}`}>
                    {page_links.title}
                  </Link>
                </Text>
              </Col>
            </Row>
          )}
        </Grid>
        <img
          className="Loljud-large"
          alt="Loljud"
          src="/assets/images/loljud-cutout.png"
        />
      </View>
    );
  }
}

export default connect(
  ({
    api: { endpoint },
    settings: { site_name: title, description, page_links }
  }) => ({
    endpoint,
    title,
    description,
    page_links
  })
)(HomeScreen);
