import React, { Component } from "react";
import { Posts, Home, Contact, Shows } from "./screens";
import { View } from "./components/base";
import { Menu, MenuButton, Screen } from "./components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { actions, Provider } from "./store";

import "normalize.css";
import "./App.css";

class App extends Component {
  componentDidMount() {
    actions.loadSettings();
  }

  render() {
    return (
      <Provider>
        <Router>
          <View className="App">
            <MenuButton />
            <Menu />
            <Screen fullscreen={true} key={1}>
              <Switch>
                <Route path="/" exact={true} component={Home} />
                <Route path="/shows" component={Shows} />
                <Route path="/contact" component={Contact} />
                <Route path="/posts/:slug?" component={Posts} />
                <Redirect to="/" />
              </Switch>
            </Screen>
          </View>
        </Router>
      </Provider>
    );
  }
}

export default App;
