import React, { Component } from "react";
import { Text, View } from "./base";
import { withRouter } from "react-router-dom";
import { connect, actions } from "../store";

import "./Menu.css";
import Social from "./Social";

class Menu extends Component {
  state = {
    open: false
  };

  toggleMenu = () => {
    const { menuVisible } = this.props;
    actions.setMenuVisible(!menuVisible);
  };

  render() {
    const { history, menuVisible } = this.props;

    return (
      <View className={`Menu Menu-${menuVisible ? "open" : "closed"}`}>
        <View className="Menu-items">
          <button
            className="Menu-item"
            onClick={() => {
              this.toggleMenu();
              history.push("/");
            }}
          >
            <Text>Home</Text>
          </button>
          <button
            className="Menu-item"
            onClick={() => {
              this.toggleMenu();
              history.push("/shows");
            }}
          >
            <Text>Shows</Text>
          </button>
          <button
            className="Menu-item"
            onClick={() => {
              this.toggleMenu();
              history.push("/posts");
            }}
          >
            <Text>Updates</Text>
          </button>
          <button
            className="Menu-item"
            onClick={() => {
              this.toggleMenu();
              history.push("/contact");
            }}
          >
            <Text>Contact</Text>
          </button>
          <Social />
        </View>
      </View>
    );
  }
}

const mapStateToProps = ({ menuVisible }) => ({ menuVisible });

export default withRouter(connect(mapStateToProps)(Menu));
